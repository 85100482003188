<template>
  <div class="page_">
    <div class="con_" v-if="step === 1">
      <p>{{ $t('JOIN[0]') || '如何加入我们的网络？' }}</p>
      <div class="con_c">
        <div class="num_">
          <span
            ><span>01</span
            ><span>{{ $t('JOIN[1]') || '网上填写申请表' }}</span></span
          >
          <span
            ><span>02</span
            ><span>{{ $t('JOIN[2]') || '中间带网络部将与您联系' }}</span></span
          >
          <span
            ><span>03</span
            ><span>{{
              $t('JOIN[3]') || '经过评估\n由网络部主管最终审核'
            }}</span></span
          >
          <span
            ><span>04</span
            ><span>{{ $t('JOIN[4]') || '合作意见邮件反馈' }}</span></span
          >
          <span
            ><span>05</span
            ><span>{{
              $t('JOIN[5]') || '如达成合作意向\n线下签订合约'
            }}</span></span
          >
        </div>
        <span @click="step = 2">{{ $t('JOIN[6]') || '开始填写申请表' }}</span>
      </div>
    </div>
    <div class="con_" v-if="step === 2">
      <p class="normal_">
        谢谢您对我们的兴趣。请填写下面的表格，我们的意料部门会联系您具体洽谈。
      </p>
      <div class="form_" ref="form">
        <a-form
          :form="form"
          class=""
          @submit="handleFormSubmit"
          layout="horizontal"
          hideRequiredMark
        >
          <div class="block_">
            <p>1、填写联系方式</p>
            <a-form-item label="医院名称" class="require_">
              <a-input
                v-decorator="[
                  'providerName',
                  {
                    rules: [{ required: true, message: '请输入医院名称' }]
                  }
                ]"
                placeholder="请输入医院名称"
              ></a-input>
            </a-form-item>
            <a-form-item label="现存Medilink客户数量">
              <a-input
                v-decorator="['customersCount', {}]"
                placeholder="请输入现存Medilink客户数量"
              ></a-input>
            </a-form-item>
            <a-form-item label="地址" class="require_">
              <a-input
                v-decorator="[
                  'address',
                  {
                    rules: [{ required: true, message: '请输入地址' }]
                  }
                ]"
                placeholder="请输入地址"
              ></a-input>
            </a-form-item>
            <a-form-item label="联系部门">
              <a-input
                v-decorator="['contactDepartment', {}]"
                placeholder="请输入联系部门"
              ></a-input>
            </a-form-item>
            <a-form-item label="联系人" class="require_">
              <a-input
                v-decorator="[
                  'contacts',
                  {
                    rules: [{ required: true, message: '请输入联系人' }]
                  }
                ]"
                placeholder="请输入联系人"
              ></a-input>
            </a-form-item>
            <a-form-item label="联系电话" class="require_">
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [{ required: true, message: '请输入联系电话' }]
                  }
                ]"
                placeholder="请输入联系电话"
              ></a-input>
            </a-form-item>
            <a-form-item label="电子邮件" class="require_">
              <a-input
                v-decorator="[
                  'email',
                  {
                    rules: [{ required: true, message: '请输入电子邮件' }]
                  }
                ]"
                placeholder="请输入电子邮件"
              ></a-input>
            </a-form-item>
            <a-form-item label="特色科室" class="require_">
              <a-input
                v-decorator="[
                  'characteristic',
                  {
                    rules: [{ required: true, message: '请输入特色科室' }]
                  }
                ]"
                placeholder="请输入特色科室"
              ></a-input>
            </a-form-item>
            <a-form-item label="您从哪里知道我们公司">
              <a-select v-decorator="['key1', {}]">
                <a-select-option value="其他医院">其他医院</a-select-option>
                <a-select-option value="现有客户">现有客户</a-select-option>
                <a-select-option value="经纪公司">经纪公司</a-select-option>
                <a-select-option value="合作伙伴">合作伙伴</a-select-option>
                <a-select-option value="朋友">朋友</a-select-option>
                <a-select-option value="网络">网络</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="如果是其他请注明">
              <a-input
                v-decorator="['key2', {}]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
            <a-form-item label="哪家保险客户在该医疗机构有需求">
              <a-input v-decorator="['key', {}]" placeholder="请输入"></a-input>
            </a-form-item>
            <a-form-item label="您希望我们用哪种方式和您联系">
              <a-select v-decorator="['key3', {}]">
                <a-select-option value="电话">电话</a-select-option>
                <a-select-option value="电子邮件">电子邮件</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="如果有任何特殊需要想与我们讨论的，请注明："
              class="flex_r"
            >
              <textarea v-model="key4"> </textarea>
            </a-form-item>
          </div>
          <div class="block_">
            <p>2、分类选择</p>
            <a-form-item label="医院名称" class="flex_r">
              <a-radio-group
                v-model="providerType"
                name="checkboxgroup"
                :options="plainOptions"
              />
            </a-form-item>
          </div>
          <a-form-item class="btn_item">
            <a-button html-type="submit" type="primary" class="btn_"
              >提交</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      step: 1,
      form: this.$form.createForm(this),
      key4: '',
      plainOptions: [
        { label: '公立医院', value: '公立医院' },
        { label: '私立医院/诊所', value: '私立医院/诊所' },
        {
          label: '专科医院/诊所（妇产科/儿科/中医/心血管）',
          value: '专科医院/诊所（妇产科/儿科/中医/心血管）'
        },
        { label: '齿科', value: '齿科' },
        { label: '体检中心', value: '体检中心' }
      ],
      providerType: []
    }
  },
  mounted() {},
  methods: {
    handleFormSubmit(e) {
      e && e.preventDefault()
      this.form.validateFields((errors, values) => {
        let postdata = { ...values }
        postdata.key4 = this.key4
        postdata.providerType = this.providerType
        postdata.status = '2'
        if (!errors) {
          if (
            !/^1[3456789]\d{9}$/.test(postdata.phone) &&
            !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(postdata.phone)
          ) {
            this.$notification.open({
              message: '手机号校验提醒',
              description: '您填写的手机号无效，请重新输入',
              duration: 4
            })
            return
          }
          if (!/\w{3}@/.test(postdata.email)) {
            this.$notification.open({
              message: '邮箱号校验提醒',
              description: '您填写的邮箱无效，请重新输入',
              duration: 4
            })
            return
          }
          this.$apis.sendMailToWeb(postdata).then(res => {
            if (res.data.code === 200) {
              this.$notification.open({
                message: '提交提醒',
                description: '提交成功！',
                duration: 4
              })
              this.step = 1
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.con_ {
  width: 1000px;
  margin: 0 auto;
  > p {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .con_c {
    .num_ {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 30px;
      background: url(../../../assets/medilink/line_.png) center 40px no-repeat;
      background-size: 1000px 2px;
      > span {
        font-size: 13px;
        color: #000;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        > span:first-child {
          font-size: 58px;
          color: rgba(0, 0, 0, 1);
          line-height: 79px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(216, 216, 216, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        > span:last-child {
          line-height: 20px;
          text-align: center;
        }
      }
    }
    > span {
      width: 125px;
      height: 33px;
      font-size: 12px;
      line-height: 33px;
      background: rgba(0, 166, 60, 1);
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: block;
      margin: 60px auto 60px;
      cursor: pointer;
      &:hover {
        background-color: #008430;
      }
    }
  }
  .normal_ {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  .form_ {
    .block_ {
      margin-bottom: 40px;
      > p {
        padding: 0 150px;
        background-color: #f9f9f9;
        font-size: 15px;
        color: #00a63c;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}
</style>
